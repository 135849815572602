<!-- 随机抽奖 -->
<template>
  <div>
    <div style="height: 3rem"></div>
    <div class="box_css">
      <div class="title_css">礼品大抽奖</div>
      <div class="box_flex_css">
        <div>
          <div class="box_name_css">随机抽奖</div>
          <div class="select_name_css">选择奖品</div>
          <div class="scrollView_top_css">
            <div @click="scrollViewC('-')">
              <i class="el-icon-arrow-left"></i>
            </div>
            <div class="scrollView">
              <div @click="selece_index = index" :class="{selece_hover:selece_index == index}" v-for="(item,index) in studentList" :key="index">
                <avatar :imagesrc="item.photo" BRnumber="8" fontSize="80" />
              </div>
            </div>
            <div @click="scrollViewC('+')">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="select_name_css">选择人数</div>
          <div class="numberPeople_box_css">
            <div
              @click="clickPeople('-')"
              :class="{ grey_Css: numberPeople <= 1 }"
            >
              <i class="el-icon-minus"></i>
            </div>
            <div>{{ numberPeople }}</div>
            <div
              @click="clickPeople('+')"
              :class="{ grey_Css: numberPeople >= studentList.length }"
            >
              <i class="el-icon-plus"></i>
            </div>
          </div>
          <div class="rollCall_bottom_css">
            <div @click="clickSelect(numberPeople,'随机')">开始抽选</div>
          </div>
        </div>
        <div>
          <div class="box_name_css">个人抽奖</div>
          <div class="luckyDraw_right_css">
            <img @click="clickSelect(1,'个人')" src="@/assets/coujjj.png" />
          </div>
        </div>
      </div>
     

    </div>
    <el-dialog
      :visible.sync="randomVisible"
      append-to-body
      :show-close="false"
      width="50%"
    >
      <div class="dialog_title_css">{{title}}抽奖</div>
       <div v-if="randomShow" class="random_css">
        <avatar :imagesrc="studentItem.photo" fontSize="13" style="margin-bottom: 1.8rem;" />
        <div class="random_name_css">{{ studentItem.userName }}</div>
        <div class="random_zc_css">正在随机抽选...</div>
      </div>
      <div v-if="rollCallShow">
        <div class="rollCall_item_css">
          <div v-for="(item, index) in studentSelect" :key="index">
            <avatar :imagesrc="item.photo" fontSize="13" style="margin-bottom: 5px;" />
            <div>{{ item.userName }}</div>
          </div>
        </div>
        <div class="rollCall_bottom_css" style="padding-top:1.5rem;">
          <div @click="clickAnew()">重新抽奖</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  GetClassUserList
} from "@/api/index.js";
let setTime = null
let setIn = null
export default {
  data() {
    return {
      classID: localStorage.getItem('classID'),
      title: '随机抽奖',
      selece_index: 0, // 随机抽奖奖品下标
      randomVisible: false, // 随机抽奖弹出
      numberPeople: 1, // 随机抽奖选择人数
      rollCallShow: false, // 随机抽奖结果
      randomShow: true, // 随机抽奖动画
      studentSelect: [], // 随机抽选结果
      studentItem: {
        name: "",
        portrait: "",
      }, // 随机时的展示
      studentList: [],
    };
  },
  created(){
    this.getClassList()
  },
  mounted(){
    
  },
  methods: {
    // 获取人员列表
    getClassList() {
      if (this.classID && this.classID != "0") {
        GetClassUserList({
          ClassID: this.classID,
          rType: 1,
        }).then((res) => {
          this.studentList = res.data
        });
      }
    },
    // 修改滚动条位置
    scrollViewC(e){
      if(setTime) return
      if (e == "+") {
        let LeftNum = 0
        setTime = setInterval(()=>{
          LeftNum+=1
          document.getElementsByClassName('scrollView')[0].scrollLeft += 28.8
          if(LeftNum >= 10){
            clearInterval(setTime)
            setTime = null
          }
        },28)
      } else if (e == "-") {
        let LeftNum = 0
        setTime = setInterval(()=>{
          LeftNum+=1
          document.getElementsByClassName('scrollView')[0].scrollLeft -= 28.8
          if(LeftNum >= 10){
            clearInterval(setTime)
            setTime = null
          }
        },28)
      }
    },
    // 修改人数
    clickPeople(e) {
      if (e == "+") {
        if (this.numberPeople < this.studentList.length) {
          this.numberPeople++;
        }
      } else if (e == "-") {
        if (this.numberPeople > 1) {
          this.numberPeople--;
        }
      }
    },
    // 重新抽选
    clickAnew() {
      this.randomVisible = false
      this.randomShow = false;
      this.rollCallShow = false;
    },
    // 开始抽选按钮
    clickSelect(numberPeople,title) {
      // numberPeople 抽奖人数，个人抽奖传1
      // title 抽奖弹窗标题
      this.title = title
      this.randomVisible = true
      this.randomShow = true;
      this.rollCallShow = false;
      if(setIn){
        clearInterval(setIn);
        setIn = null
      }
      let num = 0;
      setIn = setInterval(() => {
        let anum = Math.floor(Math.random() * this.studentList.length);
        this.studentItem = this.studentList[anum];
        num++;
        if (num > 20) {
          clearInterval(setIn);
          this.studentSelect = this.getRandomArr(
            this.studentList,numberPeople
          );
          this.randomShow = false;
          this.rollCallShow = true;
          num = 0;
        }
      }, 100);
    },
    //随机抽取
    getRandomArr(arr, count) {
      var shuffled = arr.slice(0),
        i = arr.length,
        min = i - count,
        temp,
        index;
      while (i-- > min) {
        //打乱数组
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(min);
    },
  },
};
</script>
<style scoped>
.box_css {
  width: 1200px;
  height: calc(100vh - 230px);
  margin: 0 auto;
  background-color: #ffffffda;
  border-radius: 25px;
}
.title_css {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  padding: 40px 0;
}
.numberPeople_box_css {
  display: flex;
  align-items: center;
  justify-content: center;
}
.numberPeople_box_css > div:nth-child(odd) {
  width: 60px;
  height: 60px;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eef7fe;
}
.numberPeople_box_css > div:nth-child(odd) > i {
  font-size: 28px;
  color: #5a94e6;
  font-weight: bold;
}
.grey_Css {
  background-color: #f8f8f8 !important;
}
.grey_Css > i {
  color: #c7c7c7 !important;
}
.numberPeople_box_css > div:nth-child(2) {
  font-size: 70px;
  min-width: 200px;
  padding: 0 15px;
  text-align: center;
}
.rollCall_bottom_css {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 45px;
  position: relative;
}
.rollCall_bottom_css > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 450px;
  background-color: #59a6f6;
  color: #fff;
  font-size: 20px;
  border-radius: 1000px;
  box-shadow: 0px 0px 15px #9ec0f6;
  cursor: pointer;
}
.rollCall_bottom_css > div:nth-child(2) {
  position: absolute;
  right: 50px;
}
/deep/ .el-checkbox__label {
  font-size: 18px !important;
}
/deep/ .el-checkbox__inner {
  width: 18px;
  height: 18px;
}
/deep/ .el-checkbox__inner::after {
  width: 5px;
  height: 10px;
  left: 5px;
}

.box_flex_css{
  display: flex;
  align-items: flex-start;
}
.box_flex_css>div{
  flex: 1;
  flex-shrink: 0;
  position: relative;
  padding:0 50px 30px;
  box-sizing: border-box;
}
.box_flex_css>div:nth-child(1)::after{
  content: '';
  position: absolute;
  right: 0;
  top: 50px;
  bottom: 50px;
  width:0px;
  border-right: #cacaca57 3px dashed;
}
.box_name_css{
  font-size: 25px;
  font-weight: bold;
}
.luckyDraw_right_css{
  height: 470px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.luckyDraw_right_css>img{
  width: 300px;
  height: 300px;
  cursor: pointer;
}
.scrollView{
  width: 0;
  height: 100px;
  flex: 1;
  overflow-x: auto;
  display: flex;
  align-items: center;
  padding: 0 5px;
  background-color: #e4e2e271;
  transition: all 0.5s;
}
.scrollView>div{
  flex-shrink: 0;
  height: 86px;
  width: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border-radius: 8px;
  overflow: hidden;
}
.selece_hover{
  border: #615fdb 3px dashed;
}
.scrollView>div>img{
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin: 0 !important;
  object-fit: cover;
}
.scrollView_top_css{
  display: flex;
  align-items: center;
  width: 100%;
}

.scrollView_top_css > div:nth-child(odd) {
  width: 35px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eef7fe;
}
.scrollView_top_css > div:nth-child(1){
border-radius: 10px 0 0 10px;
}
.scrollView_top_css > div:nth-last-child(1){
border-radius:0 10px 10px 0;
}
.scrollView_top_css > div:nth-child(odd) > i {
  font-size: 28px;
  color: #5a94e6;
  font-weight: bold;
}
.select_name_css{
  font-size: 20px;
  margin: 30px 0 15px;
}


/deep/ .el-dialog__header{
  display: none;
}
.dialog_title_css{
  font-size: 25px;
  font-weight: bold;
  color: #000;
  text-align: center;
}
.random_css {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 500px;
}
.random_css > img {
  height: 200px;
  width: 200px;
  flex-shrink: 0;
  border-radius: 1000px;
  margin-bottom: 25px;
}
.random_name_css {
  font-size: 35px;
  font-weight: bold;
}
.random_zc_css {
  font-size: 20px;
  color: #c7c7c7;
  margin-top: 25px;
}
.rollCall_item_css {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 420px;
  overflow-y: auto;
}
.rollCall_item_css > div {
  margin: 10px;
  font-size: 26px;
  text-align: center;
}
.rollCall_item_css > div > img {
  height: 150px;
  width: 150px;
  flex-shrink: 0;
  border-radius: 1000px;
  margin-bottom: 5px;
}
</style>